import {Popup} from "./Popup.js";

let $html = null;
let $body = null;
let $window = null;
let $popup_container = null;
let $popup_close_btn = null;
let $popup_content = null;


class Page
{
	constructor ()
	{
		/*this.$dialog_overlay = $("#dialog_overlay");
		$popup_container = this.$dialog_overlay.find("#popup_container");
		$popup_close_btn = $popup_container.find("#btn_close_popup");
		$popup_content = $popup_container.find("#popup_content");*/

		this.init();
		this.bindEvents();
	}

	init()
	{
		this.$btnLanguage = $("#btn_language");
		this.$btnLanguage.on("click", this.onChangeLanguage.bind(this));

		this.popup = new Popup();
	}

	bindEvents ()
	{
		/*this.$dialog_overlay.on('click', Page.onDialogOverlayClick);

		// popups
		$popup_close_btn.on('click', Page.closePopup.bind(this));
		$popup_content.on('click', ".action_close_popup", Page.closePopup.bind(this));
		$popup_content.on('click', ".other_btn_close_popup", Page.closePopup.bind(this));*/

	}

	onChangeLanguage(e)
	{
		e.preventDefault();
		let $a = $(e.currentTarget);
		let language = $a.attr("data-language");
		this.url = $a.attr("data-href");

		$.ajax({
			url : BASE_URL+"api/language",
			method: "POST",
			data: {language: language}
		})
		.done(this.onChangeLanguageComplete.bind(this));
	}

	onChangeLanguageComplete()
	{
		window.location.href = this.url;
	}


	// --- popup --- //
	/*static openPopup ($_content, $_elemToFocusWhenDone, isFullWidth, type) {
		Page.getHtml().addClass("is-dialog-visible-modal");
		if(isFullWidth == true) {
			$popup_container.addClass('full_width');
		}
		 let $strAriaLabel = $_content.attr("data-close-label");
		 let $strLang = $_content.attr("data-lang");
		if($strAriaLabel != "") {
			$popup_close_btn.attr("aria-label", $strAriaLabel);
		} else {
			$popup_close_btn.attr("aria-label", DEFAULT_LABEL_CLOSE_WINDOW);
		}
		if($strLang != "") {
			$popup_container.attr("lang", $strLang);
		}

		if(type == 'youtube') {
			$popup_close_btn.insertBefore($popup_content);
		}

		//console.log('DEFAULT_LABEL_CLOSE_WINDOW='+DEFAULT_LABEL_CLOSE_WINDOW);
		//console.log('$strAriaLabel='+$strAriaLabel);

		$popup_content.data('elemToFocusWhenDone', $_elemToFocusWhenDone);
		$popup_content.append($_content.clone());
		$popup_content.find(".is_popup").attr("aria-hidden", "false");

		if($popup_content_focus_trap == null) {
			$popup_content_focus_trap = new FocusTrap($popup_container);
			$popup_container.on('close', Page.onEscapeClose);
		} else {
			//console.log('refreshTabbables');
			$popup_content_focus_trap.refreshTabbables();
		}
		$popup_content_focus_trap.start();
	}
	static closePopup () {
		$popup_content_focus_trap.pause();
		Page.getHtml().removeClass("is-dialog-visible-modal");
		$popup_content.empty();
		$popup_container.removeClass('full_width');
		$popup_container.removeAttr('lang');
		$popup_close_btn.attr("aria-label", DEFAULT_LABEL_CLOSE_WINDOW);
		if($popup_content.data('elemToFocusWhenDone') != undefined
			&& $popup_content.data('elemToFocusWhenDone') != null) {
			$popup_content.data('elemToFocusWhenDone').focus();
		}
		$popup_content.insertBefore($popup_close_btn);
	}
	static onDialogOverlayClick ()
	{
		(Page.getHtml()).trigger('dialog_overlay_click');
	}

	static onEscapeClose ( _e )
	{
		//console.log('close avec esc');
		Page.closePopup();
	}*/
	// --- popup --- //

	static getHtml ()
	{
		if ( $html === null )
		{
			$html = $('html');
		}
		return $html;
	}

	static getBody ()
	{
		if ( $body === null )
		{
			$body = $('body');
		}
		return $body;
	}

	static getWindow ()
	{
		if ( $window === null )
		{
			$window = $(window);
		}
		return $window;
	}
}

export {Page};
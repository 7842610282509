import {Page} from './Page.js';
import {Form} from './Form.js';
import { Constants } from "../core/data/Constants.js";

class PageHome extends Page
{
	constructor ()
	{
		// console.log('PageHome');
		console.log('Keeper Of My Home App : version '+Constants.APP_VERSION);
		$("#version_number").text(Constants.APP_VERSION);
		super();
	}
	
	bindEvents ()
	{
		this.$form = $("form");
        
        this.$form.on("submit", this.onLogin.bind(this));
	}
    
    displayError (_index, _data)
    {
        if (_data.success != 0)
            return;
        
        let $field = $("#" + _data.field);
        
        Form.addError($field, _data.message);
        
    }
    
	onLogin (_e)
	{
		_e.preventDefault();
		
		let e = new Error();
		
		Form.clearAllErrors(this.$form);
		
		$.ajax({
			url : "/api/login",
			method: "POST",
			data: this.$form.serialize()
		})
		.done(this.onLoginComplete.bind(this));
	}
	
	onLoginComplete (_data)
	{
		if (_data.success == 0)
		{
            let arrState = _data.state;
            
            $.each(arrState, this.displayError.bind(this));
		}
		else
		{
			if(_data.more.redirectUrl != undefined) {
				window.location.href = _data.more.redirectUrl;   //"/grades";
				// console.log("window.location.href ", window.location.href );
			}
		}
	}
}

export {PageHome};

class Constants
{
    static get APP_WIDTH(){ return 1920; }
    static get APP_HEIGHT(){ return 1080; }
    static get APP_VERSION() { return 'v.0.1.11'};


    static get color_beige_gris(){ return 0xced3bf; }
    static get color_beige(){ return 0xffffeb; }
    static get color_beige_html(){ return "#ffffeb"; }
    static get color_dark(){ return 0x261e1b; }
    static get color_darker(){ return 0x28201d; }
    static get color_darker_html(){ return "#28201d"; }

    static get color_red_fail(){ return 0xda5555; }
    static get color_green_success(){ return 0x71c870; }

    static get color_bg_satchel(){ return 0x022124; }
    // static get color_bg_satchel2(){ return 0x022124; }
}

export {Constants};
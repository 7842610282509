/**
 * Created by Joanie Lessnick on 7/11/2018.
 */

import { Page } from "./Page.js";
import { PageHome } from "./PageHome.js";
import { PageGrade } from "./PageGrade.js";
import { PageSubscribe } from "./PageSubscribe.js";
import { PageGradesIndex } from "./PageGradesIndex.js";

class Main
{
	constructor()
	{
		//let $ = jQuery;
		let $body = (Page.getBody());
		let dataPageId = $body.attr('data-page-id');
		$body.addClass(dataPageId);
		// console.log('dataPageId='+dataPageId);

        
        let strColor1 = 'background: #DD353E; color: white; display: block;'
        let strColor2 = 'background: #4DBFB3; color: white; display: block;'
        let strColor3 = 'background: #A99231; color: white; display: block;'
        let strColor4 = 'background: #F09625; color: white; display: block;'
        let strColor5 = 'background: #F1632B; color: white; display: block;'

        
        console.log('%c    %c    %c Keeper Of My Home %c    %c    ', strColor1,strColor2,strColor3,strColor4,strColor5);
        // console.log('%c    %c    %c      v.0.1.9      %c    %c    ', strColor5,strColor4,strColor3,strColor2,strColor1);

		switch ( dataPageId )
		{
			case 'komh_home':
				window.page = new PageHome();
				break;
            
            case 'komh_subscribe':
                window.page = new PageSubscribe();
                break;

			case 'komh_index_grades':
				window.page = new PageGradesIndex();
				break;

			case 'komh_grade':
				window.page = new PageGrade();
				break;
		}
	}
}

$( document ).ready(function() {
	// console.log('new Main');
	let app = new Main();
});

import {Page} from './Page.js';

class PageGradesIndex extends Page
{
	constructor ()
	{
		super();
	}

	init()
	{
		super.init();

		this.$popup_content = $("#logout_popup");
		this.btn_logout = $("#btn_logout_index_grade");
	}

	bindEvents ()
	{
		super.bindEvents();
		this.btn_logout.on("click", this.onClickLogout.bind(this));
	}

	onClickLogout(ev)
	{
		ev.preventDefault();
		this.logoutUrl = $(ev.currentTarget).attr('href');
		this.popup.openPopup(this.$popup_content, this.onClickLogoutConfirm.bind(this), undefined);
	}
	onClickLogoutConfirm()
	{
		window.location.href = this.logoutUrl;
	}
}

export {PageGradesIndex};

import {Page} from './Page.js';
import {Form} from './Form.js';

class PageSubscribe extends Page
{
	constructor ()
	{
		console.log('PageSubscribe');
		super();
	}
	
	bindEvents ()
	{
		this.$form = $("form");
        
        this.$form.on("submit", this.onSubscribe.bind(this));
	}
    
    displayError (_index, _data)
    {
        if (_data.success != 0)
            return;
        
        let $field = $("#" + _data.field);
        
        Form.addError($field, _data.message);
        
    }
    
	onSubscribe (_e)
	{
		_e.preventDefault();
		
		let e = new Error();
		
		Form.clearAllErrors(this.$form);

		$.ajax({
			url : "/api/subscribe",
			method: "POST",
			dataType: "json",
			data: this.$form.serialize()
		})
		.done(this.onSubscribeComplete.bind(this));
	}
    
    onSubscribeComplete (_data)
	{
        
		if (_data.success == 0)
		{
		
            let arrState = _data.state;
            
            $.each(arrState, this.displayError.bind(this));
		}
		else
		{
            window.location.href = _data.more.redirectUrl;   //"/grades";
		}
	}
}

export {PageSubscribe};
import {Page} from './Page.js';

class PageGrade extends Page
{
	static get CLASS_MODE_VIDEO() { return 'mode_video'; }
	static get CLASS_MODE_GAME() { return 'mode_grade_game'; }
	static get CLASS_MODE_GRADE_MAP() { return 'mode_intro_grade_'; }
	static get CLASS_MODE_GRADE_HOME() { return 'mode_canada_grade_'; }

	//static get CLASS_MODE_GRADE_6_INTRO_SATCHEL() { return 'mode_grade_6_intro_satchel'; }



	constructor ()
	{
		super();
	}

	init()
	{
		super.init();
		this.$btn_back = $("#btn_back");
	}

	bindEvents ()
	{
		super.bindEvents();
		this.$btn_back.on('click', this.onClickBtnBack.bind(this));
	}

	onClickBtnBack()
	{
		//Il faut rajouter le son SFX du click sur le boutton -> TODO
		PageGrade.showLeaderBoard(false);
	}

	static goHome()
	{
		//Il faut rajouter le son SFX du click sur le boutton -> TODO
		PageGrade.showLeaderBoard(false);
	}

	static showLeaderBoard(_bShow)
	{
		// console.log("**showLeaderBoard");
		
		if(_bShow) {
			$("#btn_home").one('click', PageGrade.goHome.bind(this));
			Page.getBody().addClass('show_leaderboard');
		} else {
			$("#btn_home").off('click', PageGrade.goHome.bind(this));
			Page.getBody().removeClass('show_leaderboard');
		}
	}

	static setVideoClass()
	{
		PageGrade.__setClass(PageGrade.CLASS_MODE_VIDEO);
	}
	static setMapClass(_iGrade)
	{
		PageGrade.__setClass(PageGrade.CLASS_MODE_GRADE_MAP+_iGrade);
	}
	static setHomeClass(_iGrade)
	{
		PageGrade.__setClass(PageGrade.CLASS_MODE_GRADE_HOME+_iGrade);
	}
	static setGameClass()
	{
		PageGrade.__setClass(PageGrade.CLASS_MODE_GAME);
	}


	static __setClass(_strClass)
	{
		// console.log('--- setClass: '+_strClass+' ---');
		PageGrade.resetClass();
		Page.getBody().addClass(_strClass);
		// console.log('--- Page.getBody().attr: '+Page.getBody().attr('class')+' ---');
		this.customClass = _strClass;
	}
	static resetClass()
	{
		// console.log('--- resetClass ---');
		if(this.customClass != undefined) {
			Page.getBody().removeClass(this.customClass);
		}
	}
}

export {PageGrade};
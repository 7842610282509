
//import {LiveMessage} from './LiveMessage.js';

class Form
{
    constructor ( _$form, _bHandleSubmit = true, _closureSubmit = null )
    {
        
        if ( !_$form.is('form') )
        {
            return false;
        }
        
        this.$form = _$form;

        this.bindEvents(_bHandleSubmit, _closureSubmit);
    }
    
    bindEvents (_bHandleSubmit, _closureSubmit)
    {
        this.$form.on('focus', 'fieldset input[type="text"], fieldset input[type="password"], fieldset select, fieldset textarea', Form.setFocus);
        this.$form.on('blur', 'fieldset input[type="text"], fieldset input[type="password"], fieldset textarea', Form.removeFocus);
        this.$form.on('change blur', 'fieldset select', Form.changeSelect);
        this.$form.on('keyup', 'input, textarea', Form.checkLength);
        
        if (_bHandleSubmit)
        {
            this.$form.submit(this.submit.bind(this));
            
        }
        else if (_closureSubmit != null) //Submit is handle by another class
        {
            this.$form.submit(_closureSubmit);
            
        }
        
        this.$form.find("[data-type='uploader']").each(this.bindUploader.bind(this));
        
        this.bindDatePicker();
        //console.log(this.$form);
    }
    
    static addError ( _$el, _strMsg)
    {
        Form.clearError(_$el);
        let $fieldset = _$el.parents('fieldset');

        let idError = _$el.attr('id')+'-error';
        //_$el.attr('aria-describedby', idError);
        $fieldset.find('label').append('<span id="'+idError+'" class="error">' + _strMsg + '</span>');
        $fieldset.addClass('error');
    }
    
    static clearAllErrors ( _$form )
    {
        _$form.find('fieldset.error').removeClass("error");
        _$form.find('span.error').remove();
        let $inputs = _$form.find('input, textarea');
        //$inputs.removeAttr('aria-describedby');
        $inputs.each(function(_i, _el){
            let $el = $(_el);
            //let strDataAriaDescribedby = $el.attr('data-aria-describedby');
            //if(strDataAriaDescribedby != undefined && strDataAriaDescribedby != "") {
            //    $el.attr('aria-describedby', strDataAriaDescribedby);
            //}
        }.bind(this));
    }
    
    static changeSelect ( _e )
    {
        
        let $this = $(_e.target);
        let $fieldset = $this.parents('fieldset');
        if ( ! $this.is('select') )
        {
            $this = $fieldset.find('select');
        }
        if ( $this.val() == '' )
        {
            $fieldset.removeClass('has-content');
        }
        else
        {
            $fieldset.addClass('has-content');
        }
        
    }
    
    static setFocus ( _e )
    {
        // console.log('setFocus');
        //Form.clearError($(_e.target));
        //_$form.find('input').removeAttr('aria-describedby');
    }
    
    static clearError ( _$el )
    {
        //console.log('clearError');
        let $fieldset = _$el.parents('fieldset');
        $fieldset.addClass('has-content');
        $fieldset.removeClass('has-error');
        $fieldset.find('.error').remove();
    }
    
    static removeFocus ( _e )
    {
        //console.log('removeFocus');
        let $this = $(_e.target);
        let $fieldset = $this.parents('fieldset');
        if ( ! ($this.is('input') || $this.is('textarea')) )
        {
            $this = $fieldset.find('input, textarea');
        }
        if ( $this.val() == '' )
        {
            $fieldset.removeClass('has-content');
        }
    }

    static checkLength ( _e )
    {
        let $field = $(_e.target);
        let strValue = $field.val();
        let iMaxLength = parseInt($field.attr('maxlength'));
        //console.log( 'checkLength', $field, strValue, strValue.length, iMaxLength);
        if ( strValue.length >= iMaxLength )
        {
            _e.preventDefault();
            //LiveMessage.outputMsg( $field.attr('data-label-maxlength-reached') );
        }
    }
    
    displayError (_index, _data)
    {
        if (_data.success != 0)
            return;
        
        let $field = $("#" + _data.field);

        Form.addError($field, _data.message);
        
    }
    
    submit (_e)
    {
        _e.preventDefault();
        // console.log("Submit");

        
        Form.clearAllErrors(this.$form);
        
        let objData = this.$form.serialize();
        
        $("#btn_submit").prop("disabled", true);
        
        $.ajax(
            {
                url:`/api/form`,
                data: objData,
                dataType:"JSON",
                method:"POST"
            }
        ).done (this.onSubmit.bind(this));
    }
    
    onSubmit (_data)
    {

        
        if (_data.success == 1)
        {
            this.onSuccess(_data);
            
            return;
        }
        
        let arrState = _data.state;
        
        $.each(arrState, this.displayError.bind(this));
        
        let $firstError = $("span.error").first().parent("fieldset");
        //console.log($firstError);
        $firstError.find("input").focus();
        let $header = $("header");
        
        let iScrollTo = $firstError.offset().top - $header.height() - 20;
        
        $('html, body').animate({
            scrollTop: iScrollTo
        }, 250);
    
        $("#btn_submit").prop("disabled", false);

    }
    
    onSuccess (_data)
    {
        this.$form.unbind("submit");
        this.$form.submit();
        
        //console.log("on soumet le formulaire");
        //console.log(this.$form);
        //this.$form.append(_data.success_message);
        
    }
}

export {Form};

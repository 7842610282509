/**
 * Created by Joanie Lessnick on 10/3/2018.
 */
class Popup
{
	constructor (_strOverlaySelector)
	{
		var strOverlaySelector = _strOverlaySelector;
		if(strOverlaySelector == undefined) {
			strOverlaySelector = "#overlay_popup";
		}
		this.$overlay = $(strOverlaySelector);
		this.$btnClose = this.$overlay.find(".btn_close");
		this.$btnOk = this.$overlay.find(".btn_ok");

		this.$content_selector = null;
		this.init();
		this.bindEvents();
	}

	init () {
		this.$popup_container = this.$overlay.find("div.popup_container");
	}

	bindEvents () {
		this.$btnOk.on("click", this.closePopupOk.bind(this));
		this.$btnClose.on("click", this.closePopupCancel.bind(this));
	}

	openPopup($_selector, _handlerConfirm, _handlerCancel) {
		this.$content_selector = $_selector;
		$_selector.after('<div id="popup_placeholder"></div>');
		this.$popup_container.append($_selector);
		this.handlerConfirm = _handlerConfirm;
		this.handlerCancel = _handlerCancel;

		$("body").addClass('show_popup');
	}

	closePopupOk (ev) {
		this.closePopup(ev);
		if(this.handlerConfirm != undefined) {
			this.handlerConfirm();
		}
	}
	closePopupCancel (ev) {
		this.closePopup(ev);
		if(this.handlerCancel != undefined) {
			this.handlerCancel();
		}
	}
	closePopup (ev) {
		console.log('Popup closePopup');
		ev.preventDefault();
		$("body").removeClass('show_popup');
		console.log('Popup closePopup');

		if(this.$content_selector != null)
		{
			let $popup_placeholder = $("#popup_placeholder");
			console.log($popup_placeholder);
			$popup_placeholder.after(this.$content_selector);
			$popup_placeholder.remove();
			this.$popup_container.empty();
			this.$content_selector = null;
		}
	}
}

export {Popup};